"use strict";

import config from "@/config.json";
import AppError from "@/utils/AppError.js";

/**
 * State object
 */
const state = () => ({
  token: "",
  user: null
});

// Getter functions
const getters = {
  isAuthenticated: state => !!state.token,
  getUser: state => state.user
};

// Actions
const actions = {  
  /**
   * Auto login
   */
  autoLogin({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      //const token = localStorage.getItem("auth-token");
      const token = window.$cookies.get('auth-token');
      if (!token) {
        reject(new AppError("NO_TOKEN", true, false));
        //window.location.href = "http://localhost:8080";
        return;
      }
      dispatch("util/startLoading", null, { root: true });
      window.api.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      window
        .api({
          url: config.api.route.authCheckToken,
          method: "GET"
        })
        .then(resp => {
          console.log(resp)
          const user = resp.data;
          commit("authSuccess", user);
          resolve(user);         
        })
        .catch(err => {
          dispatch("logout");
          reject(err);
        })
        .finally(() => {
          dispatch("util/stopLoading", null, { root: true });
        });
    });
  },

  /**
   * Logout
   */
  logout({ commit, dispatch }) {
    
    return new Promise((resolve, reject) => {
      if (
        typeof window.api.defaults.headers.common["Authorization"] ===
        "undefined"
      ) {
        //localStorage.removeItem("auth-token");
        window.$cookies.remove('auth-token');
        commit("logout");
        resolve();
        return;
      }

      dispatch("util/startLoading", null, { root: true });
      window
        .api({
          url: config.api.route.authLogout,
          method: "GET"
        })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          //localStorage.removeItem("auth-token"); 
          
          window.$cookies.remove("auth-token", null, ".assetfloow.com");
          delete window.api.defaults.headers.common["Authorization"];
          commit("logout");
          dispatch("util/stopLoading", null, { root: true });
        });
    });
  },

};

// Mutations
const mutations = {
  authSuccess(state, user) {
    state.token = user.auth_token;
    state.user = user;
  },
  logout(state) {
    state.token = "";
    state.user = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
