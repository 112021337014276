"use strict";

import config from "@/config.json";
import axios from "axios";
import Vue from "vue";
import AppError from "@/utils/AppError.js";

const _api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URI,
  timeout: config.api.timeout
});


_api.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_api.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return new Promise((resolve, reject) => {

      if (error.response === undefined) { 
        reject(new AppError("SERVER_REJECT_ACCESS"));
        //return;
      }

      var appError = new AppError("UNKNOWN");

      // TODO: Configure backend to send some specific error codes to show personalized error messages.
      /*if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code
      ) {
        appError = new AppError(
          error.response.data.error.code
        );
      }*/

      reject(appError);
    });
  }
);

window.api = _api;
Vue.prototype.$api = _api;
