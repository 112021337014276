import Vue from 'vue'
import VueRouter from 'vue-router'
import AppError from "../utils/AppError.js";
import store from "../store/index.js";

Vue.use(VueRouter)


const routes = [
  {
    path: "*",
    component: () => import("@/views/NotFound.vue")
  },
  {
    path: '/',
    redirect: { name: "Dashboard" },
    meta: {
      requiresRegularOrManagerUser: true
    },
  },
  
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue')
  },
  
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import('@/views/Leaderboard.vue')
  },
  {
    path: '/segments',
    name: 'Segments',
    component: () => import('@/views/Segments.vue')
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: {
      requiresAdmin: true
    },
    component: () => import('@/views/Companies.vue')
  },
  {
    path: '/stores',
    name: 'Stores',
    meta: {
      requiresAdmin: true
    },
    component: () => import('@/views/Stores.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAdmin: true
    },
    component: () => import('@/views/Users.vue')
  },
  {
    path: '/demos',
    name: 'Demos',
    meta: {
      requiresAdmin: true
    },
    component: () => import('@/views/Demos.vue')
  },

   {
    path: '/analytics',
    name: 'Analytics',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/Analytics.vue'),
    props: true
  }, 
  {
    path: '/ai-search',
    name: 'AISearch',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/AISearch.vue'),
    props: true
  },
  {
    path: '/simulator',
    name: 'Simulator',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/Simulator.vue')
  },
  {
    path: '/optimizer',
    name: 'Optimizer',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/Optimizer.vue')
  },
  {
    path: '/find-product',
    name: 'FindProduct',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/FindProduct.vue')
  },
  {
    path: '/newsimulator',
    name: 'NewSimulator',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/NewSimulator.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAdminOrRegularOrManagerUser: true,
    },
    component: () => import('@/views/Settings.vue')
  },
   {
    path: '/planogram',
    name: 'Planogram',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/Planogram.vue')
  }, 
  /*{
    path: '/marketplace',
    name: 'Marketplace',
    meta: {
      requiresRegularOrManagerUser: true
    },
    component: () => import('@/views/Marketplace.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters["auth/getUser"]) {
    if (to.name == "Dashboard" && store.getters["auth/getUser"]["type"]["id"] === 1) {
      next({ name: "Companies" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdminOrRegularOrManagerUser)) {
    if (store.getters["auth/getUser"]) {
      if (store.getters["auth/getUser"]["type"]["id"] === 3 || store.getters["auth/getUser"]["type"]["id"] === 2 || store.getters["auth/getUser"]["type"]["id"] === 1) {
      next();
      return;
      } else {
        const error = new AppError("ROUTE_NO_PERMISSION");
        if (store.getters["auth/isAuthenticated"]) {
          store.dispatch("alert/showError", error.message);
        }
        next(error);
        return;
      }       
    }
    const error = new AppError("ROUTE_NO_PERMISSION");
    store.dispatch("alert/showError", error.message);
    next(error);
    next("/")
    return;
  }
  next()
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresRegularOrManagerUser)) {
    if (store.getters["auth/getUser"]) {
      if (store.getters["auth/getUser"]["type"]["id"] === 3 || store.getters["auth/getUser"]["type"]["id"] === 2) {
      next();
      return;
      } else {
        const error = new AppError("ROUTE_NO_PERMISSION");
        if (store.getters["auth/isAuthenticated"]) {
          store.dispatch("alert/showError", error.message);
        }
        next(error);
        next({ name: "Dashboard" });
        return;
      }       
    }
    const error = new AppError("ROUTE_NO_PERMISSION");
    store.dispatch("alert/showError", error.message);
    next(error);
    next("/")
    return;
  }
  next()
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresManager)) {
    if (store.getters["auth/getUser"]) {
      if (store.getters["auth/getUser"]["type"]["id"] === 2) {
      next();
      return;
      } else {
        const error = new AppError("ROUTE_NO_PERMISSION");
        if (store.getters["auth/isAuthenticated"]) {
          store.dispatch("alert/showError", error.message);
        }     
        next(error);
        next({ name: "Dashboard" });
        return;
      }       
    }
    const error = new AppError("ROUTE_NO_PERMISSION");
    next(error);
    next("/")
    return;
  }
  next()
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters["auth/getUser"]) {
      if (store.getters["auth/getUser"]["type"]["id"] === 1) {
      next();
      return;
      } else {
        const error = new AppError("ROUTE_NO_PERMISSION");
        if (store.getters["auth/isAuthenticated"]) {
          store.dispatch("alert/showError", error.message);
        }
        next(error);
        next({ name: "Dashboard" });
        return;
      }       
    }
    const error = new AppError("ROUTE_NO_PERMISSION");  
    store.dispatch("alert/showError", error.message);
    next(error);
    next("/")
    return;
  }
  next()
});

export default router
