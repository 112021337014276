import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
    themes: {
      light: {
        primary: '#002147',
        secondary: '#FF8F06',
        thirdiary: "#FF899C",
        mediumblue: "#0044A9",
        lightblue: "#0067FF",
        grey: "#ABB3BF",
        lightgrey: "#F0F2F5",
        accent: '#DDDDDD',
        error: '#b71c1c',
        },
    },
  },
});
