<template>
  <v-app>
    <!-- Navigation Bar -->
    <v-navigation-drawer app width="250" permanent color="white" class="pa-4">
      <v-col class="pt-6 d-flex flex-column ml-1">
        <v-img alt="Logo" src="./assets/logo.png" width="135" contain> </v-img>
      </v-col>
      <v-list-item>
        <v-col>
          <v-row class="py-4">
            <v-select
              v-model="formInputStore"
              :items="storesArraySorted"
              item-text="name"
              color="primary"
              @change="setStore"
              append-icon="mdi-chevron-down"
              v-if="$store.getters['auth/getUser']['type']['id'] != 1"
            ></v-select>
          </v-row>
        </v-col>
      </v-list-item>
      <v-list dense nav class="pa-0">
        <template v-for="item in menu">
          <v-list-item
            :key="item.title"
            link
            :ripple="false"
            :to="item.to"
            color="primary"
            v-if="
              item.permissions === undefined ||
              ($store.getters['auth/getUser'] !== null &&
                item.permissions.includes(
                  $store.getters['auth/getUser']['type']['id']
                ))
            "
          >
            <v-list-item-icon class="pl-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n6">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider
          style="margin-top: 50px; margin-bottom: 20px"
          class="mx-4"
          v-if="$store.getters['auth/getUser']['type']['id'] != 1"
        ></v-divider>
        <v-list-item
          :ripple="false"
          @click.stop="importFileDialog = true"
          color="primary"
          v-if="$store.getters['auth/getUser']['type']['id'] != 1"
        >
          <v-list-item-icon class="pl-2">
            <v-icon>mdi-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n6">
            <v-list-item-title>{{ $t("menu.upload") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-for="item in secondMenu">
          <v-list-item
            :key="item.title"
            :ripple="false"
            link
            :to="item.to"
            color="primary"
            v-if="
              item.permissions === undefined ||
              ($store.getters['auth/getUser'] !== null &&
                item.permissions.includes(
                  $store.getters['auth/getUser']['type']['id']
                ))
            "
          >
            <v-list-item-icon class="pl-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n6">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <v-list-item dense link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <!-- !Navigation Bar -->
    <!-- Import File Dialog -->
    <v-dialog
      v-model="importFileDialog"
      width="380"
      style="background-color: red"
      class="pa-10"
      @click:outside="closeImportFileDialog"
    >
      <v-card style="padding: 1rem">
        <v-card-title class="text-h6 primary--text">
          {{ $t("uploadFiles") }}
        </v-card-title>
        <v-card-text class="mt-2">
          <!-- <v-col>
             <v-btn color="primary" class="ml-n4" outlined> View Files </v-btn> 
            <v-file-input
              accept=".xlsx, .xls, .csv"
              class="ml-n4 mt-4"
              v-model="inputProductLocationsFile"
              show-size
              small-chips
              label="Product Locations File"
              prepend-icon="mdi-paperclip"
              hide-details="auto"
              @click="checkFile = ''"
              @change="inputChanged(inputProductLocationsFile, 'products')"
            ></v-file-input>
            <p class="red--text mt-2 ml-5" v-show="show">
              {{ message }}
            </p>
          </v-col> -->
          <v-col>
            <v-file-input
              accept=".xlsx, .xls, .csv"
              class="ml-n4"
              v-model="inputSalesDataFile"
              show-size
              outlined
              small-chips
              :label="$t('salesDataFile') + $t('required')"
              prepend-icon="mdi-paperclip"
              hide-details="auto"
              @click="checkFile = ''"
              @change="inputChanged(inputSalesDataFile, 'sales')"
            ></v-file-input>
            <p class="red--text mt-2 ml-5" v-show="show2">
              {{ message2 }}
            </p>
          </v-col>
          <p class="red--text mt-2 ml-7">
            {{ checkFile }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click.stop="closeImportFileDialog"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="importDataFile"
            style="text-transform: unset !important"
          >
            {{ $t("import") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !Import File Dialog -->
    <v-main class="main">
      <!-- Loading Overlay -->
      <v-overlay
        :value="this.$store.getters['util/isLoading']"
        class="align-center justify-center"
        style="margin-left: 250px"
        z-index="2"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <!-- !Loading Overlay -->
      <v-menu style="top: 92px; left: 888px">
        <template v-slot:activator="{ on }">
          <v-btn
            class="text-none"
            stacked
            :color="nots > 0 ? 'primary' : 'transparent'"
            elevation="0"
            v-on="on"
            @click="toggleSelect"
            style="
              position: fixed;
              right: 0;
              top: 1rem;
              z-index: 100;
              height: 70px;
              min-width: 70px;
            "
          >
            <v-badge
              :content="content"
              :color="nots > 0 ? 'error' : 'transparent'"
              overlap
            >
              <v-icon :color="nots > 0 ? 'white' : 'primary'"
                >mdi-bell-outline</v-icon
              >
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in notifications" :key="i">
            <v-list-item-title>
              {{ item }}
              <!--<v-alert dense type="info">
                {{ item }}
              </v-alert>-->
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card
        style="
          background-color: #f5f5f5;
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
          box-shadow: '';
          height: 100%;
        "
      >
        <v-container fluid class="pa-12">
          <router-view></router-view>
        </v-container>
      </v-card>
    </v-main>
    <!-- Alert Snackbar -->
    <v-snackbar
      v-bind:value="$store.getters['alert/status']"
      :color="$store.getters['alert/color']"
      top
      @input="$store.dispatch('alert/close')"
    >
      {{ $store.getters["alert/text"] }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          x-small
          @click="$store.dispatch('alert/close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- !Alert Snackbar -->
  </v-app>
</template>
<style scoped>
.v-menu__content--fixed {
  top: 86px !important;
}

.v-list-item__title {
  font-size: 17px !important;
  line-height: initial !important;
}
.theme--light.v-icon {
  color: #9b9b9b;
}
.menu-divider {
  background-color: #f2f2f2;
  height: 1px;
  width: 80%;
  margin-left: 18px;
}
.main {
  background-color: white;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}

.theme--light.v-list-item:hover::before {
  opacity: 0;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: gray;
}

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: blue !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
<script>
// @ is an alias to /src
import AppError from "@/utils/AppError.js";
export default {
  data() {
    return {
      mini: true,
      imagePath: null,
      // Store inputs
      formInputStore: null,
      selectedStore: null,
      stores: [],

      userCompany: null,

      // File inputs
      inputProductLocationsFile: null,
      inputSalesDataFile: null,

      // File inputs messages
      show: false,
      show2: false,
      message2: "",
      message: "",
      checkFile: "",

      // Dialogs
      importFileDialog: false,

      isOpen: false,
      notifications: [],
      content: 0,
    };
  },

  mounted: function () {
    this.getNotifications();
    this.$store.dispatch(
      "util/setLanguage",
      localStorage.getItem("language") || "en"
    );

    this.loadStores();
  },

  computed: {
    menu() {
      return [
        {
          title: this.$t("menu.insights"),
          icon: "mdi-home-outline",
          permissions: [2, 3],
          to: { name: "Dashboard" },
        },
        {
          title: this.$t("menu.companies"),
          icon: "mdi-domain",
          permissions: [1],
          to: { name: "Companies" },
        },
        {
          title: this.$t("menu.stores"),
          icon: "mdi-store",
          permissions: [1],
          to: { name: "Stores" },
        },
        {
          title: this.$t("menu.users"),
          icon: "mdi-account-multiple",
          permissions: [1],
          to: { name: "Users" },
        },
        {
          title: this.$t("menu.demos"),
          icon: "mdi-list-box-outline",
          permissions: [1],
          to: { name: "Demos" },
        },
        {
          title: this.$t("menu.analytics"),
          icon: "mdi-finance",
          permissions: [2, 3],
          to: { name: "Analytics" },
        },
        {
          title: this.$t("menu.aiSearch"),
          icon: "mdi-text-box-search-outline",
          permissions: [2, 3],
          to: { name: "AISearch" },
        },
        {
          title: this.$t("menu.planogram"),
          icon: "mdi-floor-plan",
          permissions: [2, 3],
          to: { name: "Planogram" },
        },
        {
          title: this.$t("menu.optimizer"),
          icon: "mdi-newspaper-plus",
          permissions: [2, 3],
          to: { name: "Optimizer" },
        },
      ];
    },
    secondMenu() {
      return [
        {
          title: this.$t("menu.simulator"),
          icon: "mdi-calendar-month",
          permissions: [2, 3],
          to: { name: "Simulator" },
        },
        {
          title: this.$t("menu.findProduct"),
          icon: "mdi-magnify",
          permissions: [2, 3],
          to: { name: "FindProduct" },
        },
        /*
        {
          title: "this.$t("menu.marketplace")",
          icon: "mdi-store",
          permissions: [2, 3],
          to: { name: "Marketplace" },
        },*/
        {
          title: this.$t("menu.settings"),
          icon: "mdi-cog-outline",
          permissions: [1, 2, 3],
          to: { name: "Settings" },
        },
      ];
    },
    storesArraySorted: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.stores.concat().sort(compare);
    },
    nots() {
      return this.notifications ? this.notifications.length : null;
    },
  },

  methods: {
    /**
     * Import files.
     * @public
     */
    importDataFile() {
      if (!this.inputProductLocationsFile && !this.inputSalesDataFile) {
        this.checkFile = this.$t("checkFile");
        return;
      }
      if (
        !this.inputSalesDataFile ||
        (this.inputSalesDataFile && this.inputSalesDataFile.length == 0)
      ) {
        this.checkFile = this.$t("checkFile");
        return;
      }

      if (this.message || this.message2) {
        return;
      }

      this.closeImportFileDialog();

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.importFiles;
      url = url.replace(":id", this.selectedStore.id);

      const formData = new FormData();
      if (this.inputProductLocationsFile) {
        formData.append("type", "generic_product_location");
        formData.append("file", this.inputProductLocationsFile);
      }

      if (this.inputSalesDataFile) {
        formData.append("type", "generic_sales_data");
        formData.append("file", this.inputSalesDataFile);
      }

      this.$api({
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
      })
        .then(() => {
          this.resetImportFileInputs();
          this.$store.dispatch("alert/showSuccess", this.$t("filesImported"));
          this.getStore(this.selectedStore);
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Close import files dialog.
     * @public
     */
    closeImportFileDialog() {
      this.importFileDialog = false;
      this.checkFile = "";
    },
    resetImportFileInputs() {
      this.inputProductLocationsFile = null;
      this.inputSalesDataFile = null;
    },
    /**
     * Handle file input change.
     * @param {file} inputFile - the file.
     * @param {string} type - the type of the file.
     * @public
     */
    inputChanged(inputFile, type) {
      if (inputFile) {
        if (
          inputFile.type.match(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) ||
          inputFile.type.match(".csv")
        ) {
          if (type == "products") {
            this.show = false;
          } else {
            this.show2 = false;
          }
        } else {
          if (type == "products") {
            this.show = true;
            this.message = this.$t("excelFile");
          } else {
            this.show2 = true;
            this.message2 = this.$t("excelFile");
          }
        }
      } else {
        if (type == "products") {
          this.show = false;
        } else {
          this.show2 = false;
        }
      }
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    closeSelect() {
      this.isOpen = false;
    },
    getNotifications() {
      this.content = 0;
      this.notifications = [];
    },
    /**
     * Get the user company.
     * @public
     */
    loadUserCompany() {
      var user = this.$store.getters["auth/getUser"];
      let url = this.$config.api.route.companiesAction;
      url = url.replace(":id", user.company_id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.userCompany = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {});
    },
    /**
     * Save the selected store in cache.
     * @public
     */
    setStore() {
      this.selectedStore = this.stores.find(
        (store) => store.name === this.formInputStore
      );
      localStorage.setItem("store", JSON.stringify(this.selectedStore));
      if (this.$route.name != "Dashboard") {
        this.$router.push({ name: "Dashboard" });
      } else {
        window.location.reload();
      }
    },
    /**
     * Save the selected store in cache.
     * @public
     */
    getStore(store) {
      let url = this.$config.api.route.store;
      url = url.replace(":id", store.id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.selectedStore = resp.data;
          localStorage.removeItem("store");
          localStorage.setItem("store", JSON.stringify(this.selectedStore));
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {});
    },
    /**
     * Get the users stores.
     * @public
     */
    loadStores() {
      var user = this.$store.getters["auth/getUser"];
      if (user.type.id != 1) {
        let url = this.$config.api.route.usersStores;
        url = url.replace(":id", user.id);
        this.$api({
          url: url,
          method: "GET",
        })
          .then((resp) => {
            this.stores = resp.data;
            var lastStore = localStorage.getItem("store");
            if (lastStore) {
              this.formInputStore = JSON.parse(lastStore);
              this.getStore(JSON.parse(lastStore));
            } else {
              this.formInputStore = this.stores[0];
              this.selectedStore = this.formInputStore;
              localStorage.setItem(
                "store",
                JSON.stringify(this.formInputStore)
              );
            }
          })
          .catch((err) => {
            AppError.handle(err);
          })
          .finally(() => {});
      }
    },
    /**
     * Logout the app.
     */
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        localStorage.removeItem("store");
        this.$store.dispatch("util/goToWebsite");
      });
    },
  },
};
</script>
