"use strict";
import store from "@/store/index.js";
import { i18n } from "@/plugins/i18n";

class AppError extends Error {
  constructor(code, warning = false, display = true) {
    super();
    this.message = display === true ? i18n.t("errors." + code) : code;
    this.code = code;
    this.warning = warning;
    this.display = display;
    Error.captureStackTrace(this, AppError);
  }

  static handle(error) {
    if (error instanceof AppError) {
      if (error.warning === true) {
        if (error.display === true) {
          store.dispatch("alert/showWarning", error.message);
        }

        if (process.env.NODE_ENV === "development") {
          console.warn(error); // eslint-disable-line no-console
        }
      } else {
        if (error.display === true) {
          store.dispatch("alert/showError", error.message);
        }

        if (process.env.NODE_ENV === "development") {
          console.error(error); // eslint-disable-line no-console
        }
      }
    } else {
      if (process.env.NODE_ENV === "development") {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
}

export { AppError as default };
