/**
 * State object
 */
const initialState = () => ({
  status: false,
  text: "",
  color: ""
});
const state = initialState();

// Getter functions
const getters = {
  status: state => state.status,
  text: state => state.text,
  color: state => state.color
};

// Actions
const actions = {
  showSuccess({ commit }, text) {
    commit("show", { text: text, color: "success" });
  },
  showWarning({ commit }, text) {
    commit("show", { text: text, color: "warning" });
  },
  showError({ commit }, text) {
    commit("show", { text: text, color: "error" });
  },
  close({ commit }) {
    commit("reset");
  }
};

// Mutations
const mutations = {
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  show(state, data) {
    state.status = true;
    state.text = data.text;
    state.color = data.color;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
