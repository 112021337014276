import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import config from "./config";
import "./styles/main.scss";
import "./plugins/api";
import AppError from "./utils/AppError";
import * as Vue2Leaflet from "vue2-leaflet";
import L from "leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import VueCookies from "vue-cookies";

import { i18n, loadLanguageAsync } from "@/plugins/i18n";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.imagePath = ".";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.config.productionTip = false;
Vue.prototype.$config = config;

// Auto login
store
  .dispatch("auth/autoLogin")
  .then((user) => {
    if (user.token == undefined) {
      AppError.handle(new AppError("INVALID_TOKEN"));
      store.dispatch("util/goToWebsite")
      return;
    }
     // Set app language
     loadLanguageAsync(
        localStorage.getItem("language") ||
          "en"
      );

    new Vue({
      vuetify,
      router,
      store,
      Vue2Leaflet,
      L,
      VueCookies,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((err) => {
    AppError.handle(err);
    store.dispatch("util/goToWebsite");
  });
