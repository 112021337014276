import Vue from 'vue'
import Vuex from 'vuex'
import planogram from './modules/planogram'
import util from './modules/util'
import alert from './modules/alert'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        planogram,
        util,
        alert,
        auth
    },
    strict: true
});

