/**
 * State object
 */
const state = () => ({
    productsDialog: false,
    depthDialog: false,
    category: {},
    product: {}
});

// Getter functions
const getters = {
    productsDialog: state => state.productsDialog,
    depthDialog: state => state.depthDialog,
    products: state => state.category.products,
    name: state => state.category.name,
    category: state => state.category,
    code: state => state.category.key,
    product: state => state.product
};

// Actions
const actions = {
    showProductsDialog({ commit }) {
        commit("setProductsDialog", true);
    },
    hideProductsDialog({ commit } ) {
        commit("setProductsDialog", false);
    },
    showDepthDialog({ commit }) {
        commit("setDepthDialog", true);
    },
    hideDepthDialog({ commit } ) {
        commit("setDepthDialog", false);
    },
    addCategory({ commit }, category) {
        commit("setCategory", category);
    },
    addProduct({ commit }, product) {
        commit("setProduct", product);
    },
    addDepth({ commit }, depth) {
        commit("setDepth", depth);
    },
    addWidth({ commit }, width) {
        commit("setWidth", width);
    },
    removeProduct({ commit }) {
        commit("removeProduct");
    },
    removeCategory({ commit }) {
        commit("removeCategory");
    },
};

// Mutations
const mutations = {
  setProductsDialog(state, status) {
        state.productsDialog = status;
    },
  setDepthDialog(state, status) {
        state.depthDialog = status;
    },
  setCategory(state, category) {
        state.category = category;
    },
  setProduct(state, product) {
        state.product = product;
    },
  setDepth(state, depth) {
        state.product.depth = depth;
    },
  setWidth(state, width) {
        state.product.width = width;
    },
  removeProduct(state) {
        state.product = {};
    },
  removeCategory(state) {
        state.category = {};
    },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
