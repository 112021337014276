/**
 * State object
 */
const state = () => ({
  loading: false,
  uploaded: false,
  language: null
});

// Getter functions
const getters = {
  // Loader
  isLoading: state => state.loading,
  isUploaded: state => state.uploaded,
  language: state => state.language
};

// Actions
const actions = {
  // Loader
  startLoading({ commit }) {
    commit("startLoading");
  },
  stopLoading({ commit }) {
    commit("stopLoading");
  },
  startUploaded({ commit }) {
    commit("startUploaded");
  },
  stopUploaded({ commit }) {
    commit("stopUploaded");
  },
  setLanguage({commit}, language){
    commit("setLanguage", { language: language });
  },
  // Go to Frontoffice
  goToWebsite() {
    window.location.href = 'https://assetfloow.com';
  }
};

// Mutations
const mutations = {
  // Loader
  startLoading(state) {
    state.loading = true;
  },
  stopLoading(state) {
    state.loading = false;
  },
  startUploaded(state) {
    state.uploaded = true;
  },
  stopUploaded(state) {
    state.uploaded = false;
  },
  setLanguage(state, language) {
    state.language = language
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
